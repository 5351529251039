<template>
    <div class="float_window">
        <GhNoDropSelector :datablock="{id: 'type_exercise',title: $t('type_of_exercise')}">
            <template v-slot:content>
                <form id="form_type_exercise" v-if="haveData">
                    <div class="gh-content-global">
                        <div class="gh-row-content">
                            <template v-if="!$root._route.params.view">
                                <GhInputTextField
                                    :datalabel="{
                                        text: $t('code') + '*',
                                        style: 'width: 150px; text-align: right;',
                                        class: 'T15_b'
                                    }"
                                    :datainput="{
                                        required: true,
                                        id: 'type_exercise_code',
                                        name: 'type_exercise[code]',
                                        style: 'width: 200px',
                                        value: ConfigTypeExercise.data.code,
                                        type: 'text'
                                    }"
                                />
                            </template>
                            <template v-else>
                                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('code') + ':'}}</label>
                                <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{ConfigTypeExercise.data.code}}</label>
                            </template>
                        </div>
                        <div class="gh-row-content">
                            <template v-if="!$root._route.params.view">
                                <GhInputTextField
                                    :datalabel="{
                                        text: $t('name') + '*',
                                        style: 'width: 150px; text-align: right;',
                                        class: 'T15_b'
                                    }"
                                    :datainput="{
                                        required: true,
                                        id: 'type_exercise_name',
                                        name: 'type_exercise[name]',
                                        style: 'min-width: 200px; width: 20%',
                                        value: ConfigTypeExercise.data.name,
                                        type: 'text',
                                    }"
                                />
                            </template>
                            <template v-else>
                                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('name') + ':'}}</label>
                                <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{ConfigTypeExercise.data.name}}</label>
                            </template>
                        </div>
                        <div class="gh-row-content">
                            <template v-if="!$root._route.params.view">
                                <GhTextarea
                                    :datalabel="{
                                        text: $t('description'),
                                        styles: 'width: 148px; min-width: 148px !important; text-align: right; margin-right: 12px;',
                                    }"
                                    :datainput="{
                                        id: 'type_exercise_description',
                                        name: 'type_exercise[description]',
                                        value: ConfigTypeExercise.data.description
                                    }"
                                />
                            </template>
                            <template v-else>
                                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('description') + ':'}}</label>
                                <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{ConfigTypeExercise.data.description}}</label>
                            </template>
                        </div>
                        <div class="gh-row-content">
                            <template v-if="!$root._route.params.view">
                                <GhTreeSelectorBlock
                                    :dataBlock="{
                                        type: 'radio',
                                        id: 'type_exercise_type',
                                        label: $t('type') + '*',
                                        title: $t('type'),
                                        text: $t('select_type') + ':',
                                        name: 'type_exercise[type][]',
                                        id_value: 'value',
                                        name_label: 'text',
                                        child_key: 'children',
                                        required: true,
                                        checkparentdata: false,
                                        style: '',
                                        label_style: 'width: 148px',
                                        popupStyle: 'min-width: 900px;width: 55%;',
                                    }"
                                    :selected_input="ConfigTypeExercise.data.type ? ConfigTypeExercise.data.type : []"
                                    :items="getFeeds.feed_type"
                                    ref="RefTypeExercise"
                                    @accept="changeType()"
                                />
                            </template>
                            <template v-else>
                                <div style="display: flex">
                                    <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('type') + ':'}}</label>
                                    <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{Object.values(ConfigTypeExercise.data.type[0])[1]}}</label>
                                </div>
                            </template>
                        </div>
                        <div class="gh-row-content striped_white" style="margin-left: 160px; padding-top: 3px; padding-bottom: 5px; padding-left: 5px;">
                            <div>
                                <div style="display: flex; flex-wrap: wrap;">
                                    <div style="display: flex; max-width: 400px; box-sizing: border-box;">
                                        <GhChecks
                                            :datalabel="{text: $t('name'), style: 'width: 130px', id: 'label_type_exercise_statement'}"
                                            :datainput="{required: false, selected: true, disabled: true, type: 'checkbox', id: 'value_type_exercise_statement', value: 1, name: 'type_exercise[config][statement][]'}"
                                        />
                                        <GhChecks
                                            :datalabel="{text: $t('mandatory'), style: 'width: 130px', id: 'label_type_exercise_required_statement'}"
                                            :datainput="{required: false, selected: true, disabled: true, type: 'checkbox', id: 'value_type_exercise_required_statement', value: 2, name: 'type_exercise[config][statement][]'}"
                                        />
                                    </div>
                                    <div style="display: flex; max-width: 400px; box-sizing: border-box;">
                                        <GhChecks
                                            :datalabel="{text: $t('responsible'), style: 'width: 130px', id: 'label_type_exercise_responsible'}"
                                            :datainput="{required: false, type: 'checkbox', id: 'value_type_exercise_responsible', value: 1, name: 'type_exercise[config][responsible][]',
                                                selected: ConfigTypeExercise.data.config && ConfigTypeExercise.data.config.responsible && ConfigTypeExercise.data.config.responsible[0] === 1,
                                                disabled: $root._route.params.view
                                            }"
                                            ref="GhCheckConfigResponsible"
                                            @click="checkGroupChecks('GhCheckConfigResponsible', 'GhCheckConfigResponsibleRequired', false)"
                                        />
                                        <GhChecks
                                            :datalabel="{text: $t('mandatory'), style: 'width: 130px', id: 'label_type_exercise_required_responsible'}"
                                            :datainput="{required: false, type: 'checkbox', id: 'value_type_exercise_required_responsible', value: 2, name: 'type_exercise[config][responsible][]',
                                                selected: ConfigTypeExercise.data.config && ConfigTypeExercise.data.config.responsible && ConfigTypeExercise.data.config.responsible[1] === 2,
                                                disabled: $root._route.params.view
                                            }"
                                            ref="GhCheckConfigResponsibleRequired"
                                            @click="checkGroupChecks('GhCheckConfigResponsible', 'GhCheckConfigResponsibleRequired', true)"
                                        />
                                    </div>
                                    <div style="display: flex; max-width: 400px; box-sizing: border-box;">
                                        <GhChecks
                                            :datalabel="{text: $t('information'), style: 'width: 130px', id: 'label_type_exercise_information'}"
                                            :datainput="{required: false, type: 'checkbox', id: 'value_type_exercise_information', value: 1, name: 'type_exercise[config][information][]',
                                                selected: ConfigTypeExercise.data.config && ConfigTypeExercise.data.config.information && ConfigTypeExercise.data.config.information[0] === 1,
                                                disabled: $root._route.params.view
                                            }"
                                            ref="GhCheckConfigInformation"
                                            @click="checkGroupChecks('GhCheckConfigInformation', 'GhCheckConfigInformationRequired', false)"
                                        />
                                        <GhChecks
                                            :datalabel="{text: $t('mandatory'), style: 'width: 130px', id: 'label_type_exercise_required_information'}"
                                            :datainput="{required: false, type: 'checkbox', id: 'value_type_exercise_required_information', value: 2, name: 'type_exercise[config][information][]',
                                                selected: ConfigTypeExercise.data.config && ConfigTypeExercise.data.config.information && ConfigTypeExercise.data.config.information[1] === 2,
                                                disabled: $root._route.params.view
                                            }"
                                            ref="GhCheckConfigInformationRequired"
                                            @click="checkGroupChecks('GhCheckConfigInformation', 'GhCheckConfigInformationRequired', true)"
                                        />
                                    </div>
                                    <div style="display: flex; max-width: 400px; box-sizing: border-box;" v-if="showLegend">
                                        <GhChecks
                                            :datalabel="{text: $t('score_legend'), style: 'width: 130px', id: 'label_type_exercise_score_legend'}"
                                            :datainput="{required: false, type: 'checkbox', id: 'value_type_exercise_score_legend', value: 1, name: 'type_exercise[config][score_legend][]',
                                                selected: ConfigTypeExercise.data.config && ConfigTypeExercise.data.config.score_legend && ConfigTypeExercise.data.config.score_legend[0] === 1,
                                                disabled: $root._route.params.view
                                            }"
                                            ref="GhCheckConfigScoreLegend"
                                            @click="checkGroupChecks('GhCheckConfigScoreLegend', 'GhCheckConfigScoreLegendRequired', false)"
                                        />
                                        <GhChecks
                                            :datalabel="{text: $t('mandatory'), style: 'width: 130px', id: 'label_type_exercise_required_score_legend'}"
                                            :datainput="{required: false, type: 'checkbox', id: 'value_type_exercise_required_score_legend', value: 2, name: 'type_exercise[config][score_legend][]',
                                                selected: ConfigTypeExercise.data.config && ConfigTypeExercise.data.config.score_legend && ConfigTypeExercise.data.config.score_legend[1] === 2,
                                                disabled: $root._route.params.view
                                            }"
                                            ref="GhCheckConfigScoreLegendRequired"
                                            @click="checkGroupChecks('GhCheckConfigScoreLegend', 'GhCheckConfigScoreLegendRequired', true)"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="gh-row-content striped" style="margin-left: 160px; padding-top: 3px; padding-bottom: 5px; padding-left: 5px;">
                            <div>
                                <div style="display: flex; flex-wrap: wrap;">
                                    <div style="display: flex; max-width: 400px; box-sizing: border-box;">
                                        <GhChecks
                                            :datalabel="{text: $t('examples'), style: 'width: 130px', id: 'label_type_exercise_examples'}"
                                            :datainput="{required: false, type: 'checkbox', id: 'value_type_exercise_examples', value: 1, name: 'type_exercise[config][examples][]',
                                                selected: ConfigTypeExercise.data.config && ConfigTypeExercise.data.config.examples && ConfigTypeExercise.data.config.examples[0] === 1,
                                                disabled: $root._route.params.view
                                            }"
                                            ref="GhCheckConfigExamples"
                                            @click="checkGroupChecks('GhCheckConfigExamples', 'GhCheckConfigExamplesRequired', false)"
                                        />
                                        <GhChecks
                                            :datalabel="{text: $t('mandatory'), style: 'width: 130px', id: 'label_type_exercise_required_examples'}"
                                            :datainput="{required: false, type: 'checkbox', id: 'value_type_exercise_required_examples', value: 2, name: 'type_exercise[config][examples][]',
                                                selected: ConfigTypeExercise.data.config && ConfigTypeExercise.data.config.examples && ConfigTypeExercise.data.config.examples[1] === 2,
                                                disabled: $root._route.params.view
                                            }"
                                            ref="GhCheckConfigExamplesRequired"
                                            @click="checkGroupChecks('GhCheckConfigExamples', 'GhCheckConfigExamplesRequired', true)"
                                        />
                                    </div>
                                    <div style="display: flex; max-width: 400px; box-sizing: border-box;">
                                        <GhChecks
                                            :datalabel="{text: $t('bibliography'), style: 'width: 130px', id: 'label_type_exercise_bibliography'}"
                                            :datainput="{required: false, type: 'checkbox', id: 'value_type_exercise_bibliography', value: 1, name: 'type_exercise[config][bibliography][]',
                                                selected: ConfigTypeExercise.data.config && ConfigTypeExercise.data.config.bibliography && ConfigTypeExercise.data.config.bibliography[0] === 1,
                                                disabled: $root._route.params.view
                                            }"
                                            ref="GhCheckConfigBibliography"
                                            @click="checkGroupChecks('GhCheckConfigBibliography', 'GhCheckConfigBibliographyRequired', false)"
                                        />
                                        <GhChecks
                                            :datalabel="{text: $t('mandatory'), style: 'width: 130px', id: 'label_type_exercise_required_bibliography'}"
                                            :datainput="{required: false, type: 'checkbox', id: 'value_type_exercise_required_bibliography', value: 2, name: 'type_exercise[config][bibliography][]',
                                                selected: ConfigTypeExercise.data.config && ConfigTypeExercise.data.config.bibliography && ConfigTypeExercise.data.config.bibliography[1] === 2,
                                                disabled: $root._route.params.view
                                            }"
                                            ref="GhCheckConfigBibliographyRequired"
                                            @click="checkGroupChecks('GhCheckConfigBibliography', 'GhCheckConfigBibliographyRequired', true)"
                                        />
                                    </div>
                                    <div style="display: flex; max-width: 400px; box-sizing: border-box;">
                                        <GhChecks
                                            :datalabel="{text: $t('materials'), style: 'width: 130px', id: 'label_type_exercise_support_material'}"
                                            :datainput="{required: false, type: 'checkbox', id: 'value_type_exercise_support_material', value: 1, name: 'type_exercise[config][support_material][]',
                                                selected: ConfigTypeExercise.data.config && ConfigTypeExercise.data.config.support_material && ConfigTypeExercise.data.config.support_material[0] === 1,
                                                disabled: $root._route.params.view
                                            }"
                                            ref="GhCheckConfigSupportMaterial"
                                            @click="checkGroupChecks('GhCheckConfigSupportMaterial', 'GhCheckConfigSupportMaterialRequired', false)"
                                        />
                                        <GhChecks
                                            :datalabel="{text: $t('mandatory'), style: 'width: 130px', id: 'label_type_exercise_required_support_material'}"
                                            :datainput="{required: false, type: 'checkbox', id: 'value_type_exercise_required_support_material', value: 2, name: 'type_exercise[config][support_material][]',
                                                selected: ConfigTypeExercise.data.config && ConfigTypeExercise.data.config.support_material && ConfigTypeExercise.data.config.support_material[1] === 2,
                                                disabled: $root._route.params.view
                                            }"
                                            ref="GhCheckConfigSupportMaterialRequired"
                                            @click="checkGroupChecks('GhCheckConfigSupportMaterial', 'GhCheckConfigSupportMaterialRequired', true)"
                                        />
                                    </div>
                                    <div style="display: flex; max-width: 400px; box-sizing: border-box;">
                                        <GhChecks
                                            :datalabel="{text: $t('good_practices'), style: 'width: 130px', id: 'label_type_exercise_good_practices'}"
                                            :datainput="{required: false, type: 'checkbox', id: 'value_type_exercise_good_practices', value: 1, name: 'type_exercise[config][good_practices][]',
                                                selected: ConfigTypeExercise.data.config && ConfigTypeExercise.data.config.good_practices && ConfigTypeExercise.data.config.good_practices[0] === 1,
                                                disabled: $root._route.params.view
                                            }"
                                            ref="GhCheckConfigGoodPractices"
                                            @click="checkGroupChecks('GhCheckConfigGoodPractices', 'GhCheckConfigGoodPracticesRequired', false)"
                                        />
                                        <GhChecks
                                            :datalabel="{text: $t('mandatory'), style: 'width: 130px', id: 'label_type_exercise_required_good_practices'}"
                                            :datainput="{required: false, type: 'checkbox', id: 'value_type_exercise_required_good_practices', value: 2, name: 'type_exercise[config][good_practices][]',
                                                selected: ConfigTypeExercise.data.config && ConfigTypeExercise.data.config.good_practices && ConfigTypeExercise.data.config.good_practices[1] === 2,
                                                disabled: $root._route.params.view
                                            }"
                                            ref="GhCheckConfigGoodPracticesRequired"
                                            @click="checkGroupChecks('GhCheckConfigGoodPractices', 'GhCheckConfigGoodPracticesRequired', true)"
                                        />
                                    </div>
                                    <div style="display: flex; max-width: 400px; box-sizing: border-box;">
                                        <GhChecks
                                            :datalabel="{text: $t('theory'), style: 'width: 130px', id: 'label_type_exercise_theory'}"
                                            :datainput="{required: false, type: 'checkbox', id: 'value_type_exercise_theory', value: 1, name: 'type_exercise[config][theory][]',
                                                selected: ConfigTypeExercise.data.config && ConfigTypeExercise.data.config.theory && ConfigTypeExercise.data.config.theory[0] === 1,
                                                disabled: $root._route.params.view
                                            }"
                                            ref="GhCheckConfigTheory"
                                            @click="checkGroupChecks('GhCheckConfigTheory', 'GhCheckConfigTheoryRequired', false)"
                                        />
                                        <GhChecks
                                            :datalabel="{text: $t('mandatory'), style: 'width: 130px', id: 'label_type_exercise_required_theory'}"
                                            :datainput="{required: false, type: 'checkbox', id: 'value_type_exercise_required_theory', value: 2, name: 'type_exercise[config][theory][]',
                                                selected: ConfigTypeExercise.data.config && ConfigTypeExercise.data.config.theory && ConfigTypeExercise.data.config.theory[1] === 2,
                                                disabled: $root._route.params.view
                                            }"
                                            ref="GhCheckConfigTheoryRequired"
                                            @click="checkGroupChecks('GhCheckConfigTheory', 'GhCheckConfigTheoryRequired', true)"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="gh-row-content striped_white" style="margin-left: 160px; padding-top: 3px; padding-bottom: 5px; padding-left: 5px;">
                            <div>
                                <div style="display: flex; flex-wrap: wrap;">
                                    <div style="display: flex; max-width: 400px; box-sizing: border-box;">
                                        <GhChecks
                                            :datalabel="{text: $t('exercise_autoevaluation'), style: 'width: 294px', id: 'label_type_exercise_exercise_autoevaluation'}"
                                            :datainput="{required: false, type: 'checkbox', id: 'value_type_exercise_exercise_autoevaluation', value: 1, name: 'type_exercise[config][exercise_autoevaluation][]',
                                                selected: ConfigTypeExercise.data.config && ConfigTypeExercise.data.config.exercise_autoevaluation && ConfigTypeExercise.data.config.exercise_autoevaluation[0] === 1,
                                                disabled: $root._route.params.view
                                            }"
                                            ref="GhCheckConfigAutoevaluation"
                                            @click="checkGroupChecks('GhCheckConfigAutoevaluation', undefined, false)"
                                        />
                                    </div>
                                    <div style="display: flex; max-width: 400px; box-sizing: border-box;">
                                        <GhChecks
                                            :datalabel="{text: $t('not_apply'), style: 'width: 300px', id: 'label_type_exercise_exercise_not_apply'}"
                                            :datainput="{required: false, type: 'checkbox', id: 'value_type_exercise_exercise_not_apply', value: 1, name: 'type_exercise[config][not_apply][]',
                                                selected: ConfigTypeExercise.data.config && ConfigTypeExercise.data.config.not_apply && ConfigTypeExercise.data.config.not_apply[0] === 1,
                                                disabled: $root._route.params.view
                                            }"
                                            ref="GhCheckConfigNotApply"
                                            @click="checkGroupChecks('GhCheckConfigNotApply', undefined, false)"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div :key="refresh">
                            <component v-bind:is="include_component" ref="GhChildrenTypesBlock"/>
                        </div>
                    </div>
                </form>
                <template v-else-if="!haveData">
                    <img :src="require('../../../../../assets/gh_new_dessign/ajax-loader_blue.gif')" alt="">
                </template>
            </template>
        </GhNoDropSelector>
        <div class="centered_footer" style="margin-top: 8px; padding-bottom: 10px;">
            <GhButton v-if="!$root._route.params.view && haveData"
                :datainput="{
                    id: 'btn_save',
                    text: $t('save'),
                }"
                @click="submitTypeExercise"
            />
            <GhButton
                :datainput="{
                    id: 'btn_return',
                    text: $t('back'),
                }"
                @click="router.go(-1)"
            />
        </div>
    </div>
</template>

<script>
    import type_open_question from "./includes/type_open_question";
    import type_close_one_correct from "./includes/type_close_one_correct";
    import type_close_n_correct from "./includes/type_close_n_correct";
    import type_close_numeric_specific from "./includes/type_close_numeric_specific";
    import type_simple_question from "./includes/type_simple_question.vue";
    import type_questionnaire from "./includes/type_questionnaire.vue";
    import type_multi_open from "./includes/type_multi_open.vue";

    import GhNoDropSelector from 'fe-gh-no-dropselector-lib';
    import GhInputTextField from 'fe-gh-inputtextfield-lib';
    import GhTextarea from 'fe-gh-textarea-lib';
    import GhTreeSelectorBlock from 'fe-gh-tree-selector-block-lib';
    import GhChecks from 'fe-gh-checks-lib';
    import GhButton from 'fe-gh-button-lib';

    import {mapState} from "vuex";

    export default {
        name: "type_exercise",
        components: {
            GhNoDropSelector,
            GhInputTextField,
            GhTextarea,
            GhTreeSelectorBlock,
            GhChecks,
            GhButton
        },
        computed: {
            ...mapState(['ConfigTypeExercise', 'getFeeds'])
        },
        data(){
            return {
                haveData: false,
                showProcedimientos: true,
                refresh: 0,
                include_component: '',
                showLegend: true
            }
        },
        async beforeMount(){
            const token = localStorage.getItem("token");
            if (token) {
                await this.$store.dispatch('cleanTablePossibleAnswers', '', {root: true});
                await this.$store.dispatch('cleanTablePossibleQuestions', '', {root: true});

                await this.$store.dispatch('getFeedType', '', {root: true});
                await this.$store.dispatch('getFeedCondition', '', {root: true});
                await this.$store.dispatch('getFeedTypeAnswers', '', {root: true});
                await this.$store.dispatch('getTypeExerciseData', {id: this.$root._route.params.id}, {root: true});

                this.haveData = true;

                if (this.ConfigTypeExercise.data.type !== undefined) {
                    switch (this.ConfigTypeExercise.data.type[0].value) {
                        case 1:
                            this.include_component = type_open_question;
                            this.showProcedimientos = false;
                            this.showLegend = true;
                            break;
                        case 2:
                            this.include_component = type_close_numeric_specific;
                            this.showProcedimientos = false;
                            this.showLegend = true;
                            break;
                        case 3:
                            this.include_component = type_close_one_correct;
                            this.showProcedimientos = true;
                            this.showLegend = true;
                            break;
                        case 4:
                            this.include_component = type_close_n_correct;
                            this.showProcedimientos = true;
                            this.showLegend = true;
                            break;
                        case 5:
                            this.include_component = type_simple_question;
                            this.showProcedimientos = false;
                            this.showLegend = true;
                            break;
                        case 6:
                            this.include_component = type_questionnaire;
                            this.showProcedimientos = false;
                            this.showLegend = true;
                            break;
                        case 7:
                            this.include_component = type_multi_open;
                            this.showProcedimientos = false;
                            this.showLegend = false;
                            break;
                    }
                }
            }
        },
        methods: {
            async changeType(){
                if(this.$root._route.params.id === undefined){
                    this.ConfigTypeExercise.data = {
                        'code': document.getElementById('type_exercise_code').value,
                        'name': document.getElementById('type_exercise_name').value,
                        'description': document.getElementById('type_exercise_description').value
                    };
                }else {
                    this.ConfigTypeExercise.data.code = document.getElementById('type_exercise_code').value;
                    this.ConfigTypeExercise.data.name = document.getElementById('type_exercise_name').value;
                    this.ConfigTypeExercise.data.description = document.getElementById('type_exercise_description').value;
                }

                await this.$store.dispatch('cleanTablePossibleAnswers', '', {root: true});
                await this.$store.dispatch('cleanTablePossibleQuestions', '', {root: true});

                document.querySelectorAll('input[name="type_exercise[type][]"]').forEach(item => {
                    if(item.checked){
                        switch (item.value){
                            case '1':
                                this.include_component = type_open_question;
                                this.showProcedimientos = false;
                                this.showLegend = true;
                                break;
                            case '2':
                                this.include_component = type_close_numeric_specific;
                                this.showProcedimientos = false;
                                this.showLegend = true;
                                break;
                            case '3':
                                this.include_component = type_close_one_correct;
                                this.showProcedimientos = true;
                                this.showLegend = true;
                                break;
                            case '4':
                                this.include_component = type_close_n_correct;
                                this.showProcedimientos = true;
                                this.showLegend = true;
                                break;
                            case '5':
                                this.include_component = type_simple_question;
                                this.showProcedimientos = false;
                                this.showLegend = true;
                                break;
                            case '6':
                                this.include_component = type_questionnaire;
                                this.showProcedimientos = false;
                                this.showLegend = true;
                                break;
                            case '7':
                                this.include_component = type_multi_open;
                                this.showProcedimientos = false;
                                this.showLegend = false;
                                break;
                        }

                        this.refresh++;
                    }
                });
            },
            checkGroupChecks(refType, refRequired, required, children){
                var startCheck = children ? this.$refs['GhChildrenTypesBlock'] : this;
                if (required) {
                    if (startCheck.$refs[refRequired].selected) {
                        if (!startCheck.$refs[refType].selected) {
                            startCheck.$refs[refType].checkUncheck();
                        }
                    }
                } else {
                    if (refRequired !== undefined && !startCheck.$refs[refType].selected) {
                        if (startCheck.$refs[refRequired].selected) {
                            startCheck.$refs[refRequired].checkUncheck();
                        }
                    }
                }
            },
            async submitTypeExercise(){
                if(this.Global.checkRequired('form_type_exercise') === 'OK') {
                    const formData = new FormData(document.getElementById('form_type_exercise'));
                    this.$store.dispatch('setTypeExerciseData', {
                        formData: [...formData],
                        id: this.$root._route.params.id
                    }, {root: true});
                }
            }
        },
    }
</script>

<style>
    @import "fe-gh-tree-selector-lib/dist/fe-gh-tree-selector-lib.css";
    @import "fe-gh-tree-selector-lib/dist/fe-gh-tree-selector-lib.css";

    #list_possible_answers{
        min-width: 830px !important;
    }
</style>