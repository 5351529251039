<template>
    <div style="min-height: 100vh" :key="'REF' + refreshView">
        <GhCEQuestionnaireConstructor v-if="haveData"
            :block="{
                svg: {
                    add: require('@/assets/gh_new_dessign/add_plus.svg'),
                    noImg: require('@/assets/gh_new_dessign/no-image2.svg'),
                    arrowRight: require('@/assets/questionnaire/arrow-right.svg'),
                    arrowDown: require('@/assets/questionnaire/arrow-down.svg'),
                    arrowUp: require('@/assets/questionnaire/arrow-up.svg'),
                    close: '',
                    levels: {
                        '1': require('@/assets/questionnaire/level1.svg'),
                        '2': require('@/assets/questionnaire/level2.svg'),
                        '3': require('@/assets/questionnaire/level3.svg'),
                        '4': require('@/assets/questionnaire/level4.svg'),
                        '5': require('@/assets/questionnaire/level5.svg')
                    },
                    material: require('@/assets/questionnaire/book.svg'),
                    download: require('@/assets/gh_new_dessign/descargar.svg'),
                    delete: require('@/assets/gh_new_dessign/trash.svg')
                },
                pointer: {
                    colors: [
                        '#C7C7C7',
                        '#F58449',
                        '#005392'
                    ],
                    title: [
                        'for_start',
                        'in_progress',
                        'finish'
                    ]
                },
                evidence: {
                    dispatch: 'easyUpload',
                    accept: '.pdf, .xls, .xlsx, .doc, .docx, .odt',
                    remove: true,
                    removeText: '',
                    removeSvg: require('@/assets/questionnaire/trash.svg')
                }
            }"
            :dataIndex="getterQuestionnaireIndex"
            :dataBody="getterQuestionnaireBodyContent"
            :dataMaterials="getterQuestionnaireMaterial"
            :nextQuestionnary="getterNextQuestionnaire"
            :feedIndex="getterQuestionnaireIndexFeed"
            @setQuestion="handleSetQuestion"
            @getMaterial="handeGetMaterial"
            @setSave="handeSetSave"
            @setFinish="handeSetFinish"
            @watcherFinish="handleSetQuestion"
            @resetNextQuestionnaire="resetNextQuestion"
            @checkParent="handleCheckParent"
            ref="RefQuestionnaire"
        >
            <template v-slot:contentInsideMenu>
                <template v-if="!getterQuestionnaireBodyContent.evaluator && getterQuestionnaireIndex !== undefined && getterQuestionnaireIndex.finish !== undefined && getterQuestionnaireIndex.finish">
                    <GhButton
                        class="button-questionnaire-primary"
                        :datainput="{
                            id: 'btn_finish',
                            text: $t('finish')
                        }"
                        @click="setFinishAllQuestionnaire()"
                    />
                </template>
                <template v-if="$root._route.params.type !== 'grouper' && $root._route.params.type !== 'exercise' && getterQuestionnaireIndex !== undefined && getterQuestionnaireIndex.joint_map !== undefined && getterQuestionnaireIndex.joint_map">
                    <router-link :to="{name: 'joint_map', params: {
                        id: $root._route.params.id,
                        type: $root._route.params.type
                    }}">
                        <GhButton
                            class="button-questionnaire-secondary"
                            :datainput="{
                                id: 'btn_view_joint_map',
                                text: $t('view_joint_map')
                            }"
                        />
                    </router-link>
                </template>
                <GhButton
                    class="button-questionnaire-secondary"
                    :datainput="{
                        id: 'btn_view_global_map',
                        text: $t('view_global_map')
                    }"
                    :disabled="$root._route.params.type === 'grouper' || $root._route.params.type === 'exercise'"
                    @click="openMap()"
                />
            </template>
            <template v-slot:extraContent>
                <GhChartTable
                    style="margin-top: -15px;"
                    :extratable="{
                        id: 'global_map',
                        more: require('@/assets/questionnaire/arrow-down.svg'),
                        less: require('@/assets/questionnaire/arrow-up.svg'),
                        pointer: {
                            colors: [
                                '#C7C7C7',
                                '#F58449',
                                '#005392'
                            ],
                            title: [
                                'for_start',
                                'in_progress',
                                'finish'
                            ]
                        },
                    }"
                    :header="headerChart"
                    :data="getterQuestionnaireGlobalMap.data"
                    :footer="[]"
                    :type="getterQuestionnaireGlobalMap.type"
                    :evaluator="getterQuestionnaireGlobalMap.is_evaluator"
                    :states="statesChart"
                    @advice="''"
                    ref="RefGlobalMap"
                >
                    <template v-slot:advice_actions="{itemProp}">
                        <template v-if="(itemProp.hard_points !== undefined && itemProp.hard_points !== '') || (itemProp.improvement_opportunities !== undefined && itemProp.improvement_opportunities !== '')">
                            <GhAction
                                style="margin-left: 10px;"
                                class="color-light-blue T-subtitle-second underline-light-blue"
                                :dataction="{
                                    id: 'see_advice_' + itemProp.id,
                                    text: $t('see'),
                                    class: 'T-subtitle-colored',
                                    icon: require('@/assets/gh_new_dessign/view2.svg'),
                                }"
                                @click="showAdvice(itemProp)"
                            />
                        </template>
                    </template>
                    <template v-slot:punctuation_actions="{itemProp}">
                        <template v-if="itemProp.detail">
                            <GhAction
                                style="margin-left: 10px; margin-top: 1px;"
                                class="color-light-blue T-subtitle-second underline-light-blue"
                                :dataction="{
                                    id: 'detail_' + itemProp.id,
                                    text: $t('detail'),
                                    class: 'T-subtitle-colored',
                                    icon: require('@/assets/gh_new_dessign/doc.svg'),
                                }"
                                @click="showDetail(itemProp)"
                            />
                        </template>
                    </template>
                    <template v-slot:header_actions>
                        <div style="width: 100%;">
                            <div style="margin-left: 10px; margin-right: 10px; float: right; margin-top: 10px; margin-bottom: 5px;">
                                <GhButton
                                    class="button-questionnaire-secondary"
                                    :datainput="{
                                        id: 'btn_generate_pdf',
                                        text: pdfGenerate ? $t('generate_pdf') : $t('generating'),
                                        icon: pdfGenerate ? '' : require('@/assets/gh_new_dessign/ajax-loader_blue.gif')
                                    }"
                                    :disabled="!pdfGenerate"
                                    @click="generatePDF()"
                                />
                            </div>
                        </div>
                    </template>
                </GhChartTable>
            </template>
            <template v-slot:bodyHeaderActions>
                <template v-if="!getterQuestionnaireBodyContent.evaluator">
                    <template v-if="getterQuestionnaireBodyContent.actions.know_more">
                        <GhAction
                            class="color-light-blue T-subtitle-second underline-light-blue"
                            :dataction="{
                                id: 'action_know_more',
                                text: $t('know_more'),
                                icon: require('@/assets/questionnaire/hat.svg')
                            }"
                            @click="getKnowMore()"
                        />
                    </template>
                    <template v-if="getterQuestionnaireBodyContent.actions.information">
                        <div @click="getInformation()" >
                            <GhCECustomTitle :content="getterQuestionnaireInformation.information !== undefined ? getterQuestionnaireInformation.information : ''">
                                <GhAction
                                    class="color-light-blue T-subtitle-second underline-light-blue"
                                    :dataction="{
                                        id: 'action_information',
                                        text: $t('information'),
                                        icon: require('@/assets/questionnaire/information.svg'),
                                    }"
                                />
                            </GhCECustomTitle>
                        </div>
                    </template>
                </template>
            </template>
            <template v-slot:answerLegend>
                <div @click="getLegend()" style="display: flex; flex-direction: row-reverse;">
                    <GhCECustomTitle :content="getterQuestionnaireInformation.legend !== undefined ? getterQuestionnaireInformation.legend : ''">
                        <GhAction
                            class="color-light-blue T-subtitle-second underline-light-blue"
                            style="padding-right: 5px; margin-right: auto;"
                            :dataction="{
                                id: 'action_legend',
                                text: $t('legend'),
                                icon: require('@/assets/questionnaire/information.svg')
                            }"
                        />
                    </GhCECustomTitle>
                </div>
            </template>
            <template v-slot:buttonEvaluator>
                <template v-if="getterQuestionnaireBodyContent !== undefined && getterQuestionnaireBodyContent.actions !== undefined && getterQuestionnaireBodyContent.actions.evaluate">
                    <div class="no-apply-pallete">
                        <GhButton
                            :datainput="{
                                id: 'btn_evaluate',
                                text: $t('evaluate'),
                                class: 'T19 container-md_button_content button-questionnaire-primary',
                            }"
                            @click="setEvaluate()"
                        />
                    </div>
                </template>
            </template>
        </GhCEQuestionnaireConstructor>

        <component :is="popups.learning" :title="titlePopupLearning"/>
        <component :is="popups.evaluate"/>
        <component :is="popups.advice"/>
    </div>
</template>

<script>
    import GhCEQuestionnaireConstructor from "@/components/GhCEQuestionnaireConstructor.vue";
    import GhButton from "fe-gh-button-lib";
    import GhAction from "fe-gh-action-lib";
    import GhCECustomTitle from "@/components/GhCETooltip.vue";
    import GhChartTable from "fe-gh-chart-table-lib";

    import {mapGetters} from "vuex";

    import popup_learning from "@/view/shared/popup/learning.vue";
    import popup_evaluate from "@/view/shared/popup/evaluate.vue";
    import popup_advice from "@/view/shared/popup/advice.vue";

    export default {
        name: 'body_questionnaire',
        components: {
            GhCEQuestionnaireConstructor,
            GhButton,
            GhAction,
            GhCECustomTitle,
            GhChartTable
        },
        computed: {
            ...mapGetters(['getterQuestionnaireIndex', 'getterQuestionnaireBodyContent', 'getterQuestionnaireInformation', 'getterQuestionnaireMaterial', 'getterNextQuestionnaire', 'getterQuestionnaireGlobalMap', 'getterQuestionnaireIndexFeed'])
        },
        data(){
            return {
                haveData: false,
                information: '',
                titlePopupLearning: '',
                pdfGenerate: true,
                headerChart: [
                    {text: 'title', field: 'title', style: 'max-width: 50px; width: 25%;', show_drag: true},
                    {text: '', field: 'chart', chart: true, ruler: true, value_ruler: {0: 0, 1: 1, 2: 2, 3: 3, 4: 4, 5: 5}, style: 'max-width: 50px; width: 30%;'},
                    {text: '', field: 'advice', advice: true, style: 'min-width: 50px; width: 1%;'}
                ],
                statesChart: {
                    0: 'to_do',
                    1: 'unfinished',
                    2: 'completed',
                },
                popups: {
                    learning: popup_learning,
                    evaluate: popup_evaluate,
                    advice: popup_advice
                },
                refreshView: 0
            }
        },
        async beforeMount(){
            const token = localStorage.getItem("token");
            if (token) {
                this.$store.dispatch('cleanQuestionnaireLoads', '', {root: true});

                await this.$store.dispatch('getQuestionnaireIndex', {
                    id: this.$root._route.params.id,
                    type: this.$root._route.params.type
                }, {root: true});

                await this.$store.dispatch('getQuestionnaireBody', {
                    id: this.$root._route.params.id,
                    type: this.$root._route.params.type
                }, {root: true});

                if(this.$root._route.params.type === 'exercise') {
                    await this.$store.dispatch('getMaterialFromIndex', {
                        id: this.$root._route.params.id,
                        type: this.$root._route.params.type
                    }, {root: true});
                }

                this.haveData = true;
            }
        },
        watch: {
            'getterQuestionnaireBodyContent'(data){
                if(this.$refs.RefQuestionnaire !== undefined) {
                    this.$refs.RefQuestionnaire.defaultBody = data;
                }
            },
            'getterQuestionnaireGlobalMap'(data){
                if(data.puntuation !== undefined){
                    this.headerChart[1].value_ruler = data.puntuation;
                }

                if(data.type === 2 && !data.is_evaluator){
                    delete this.headerChart[2];
                    delete this.headerChart[1];
                    this.headerChart = this.headerChart.filter(n => n);
                }

                if(data.type === 2 && data.is_evaluator){
                    this.headerChart[2].field = 'punctuation'
                    delete this.headerChart[2].advice;
                }

                if(data.type === 3){
                    this.headerChart[2].field = 'punctuation'
                    this.headerChart[2].slot = true;
                    delete this.headerChart[2].advice;
                    this.headerChart[3] = {text: '', field: 'advice', slot: true, advice: true, style: 'min-width: 50px; width: 1%;'};
                }
            }
        },
        methods: {
            async generatePDF(){
                this.pdfGenerate = false;

                await this.$store.dispatch('getPDFGlobalMap', {
                    id: this.$root._route.params.id,
                    type: this.$root._route.params.type
                }, {root: true}).then((response) => {
                    if(response.status === 200){
                        if(response.data.data !== undefined) {
                            const link = document.createElement('a');
                            link.id = 'download_map_pdf';
                            link.href = response.data.data;
                            link.download = response.data.data.split('/').pop();
                            document.body.appendChild(link);
                            link.click();
                            document.body.removeChild(link);
                        }

                        this.pdfGenerate = true;
                    }
                });
            },
            async openMap(){
                await this.$store.dispatch('getQuestionnaireGlobalMap', {
                    id: this.$root._route.params.id,
                    type: this.$root._route.params.type
                }, {root: true});

                this.$refs['RefQuestionnaire'].refreshMap = true;
                this.$refs['RefQuestionnaire'].showMap = true;
            },
            async handleCheckParent(){
                this.$store.dispatch('cleanQuestionnaireLoads','', {root: true});

                await this.$store.dispatch('getQuestionnaireIndex', {
                    id: this.$root._route.params.id,
                    type: this.$root._route.params.type
                }, {root: true});

                this.handleSetQuestion();
            },
            resetNextQuestion(){
                this.$store.dispatch('resetNextQuestionnaire', '', {root: true});
            },
            async handleSetQuestion(itemIndex) {
                let id = '';

                if(itemIndex === undefined){
                    itemIndex = {id: null};
                }

                await this.$store.dispatch('getQuestionnaireFromIndex', {
                    id: this.$root._route.params.id,
                    actual_id: itemIndex.id,
                    type: this.$root._route.params.type
                }, {root: true}).then(async (response) => {
                    id = response.id;

                    if(response.type === 7){
                        await this.$store.dispatch('getQuestionnaireIndexFeed', {
                            id: this.$root._route.params.id,
                            type: this.$root._route.params.type
                        }, {root: true});
                    }

                    this.getterQuestionnaireIndex.childrens.forEach((i, k) => {
                        if(response.id === i.id){
                            i['current'] = true;
                            this.$refs['RefQuestionnaire'].current.item = i;
                            this.$refs['RefQuestionnaire'].current.key = k;
                        }
                    });
                });

                if(!this.getterQuestionnaireBodyContent.evaluator) {
                    await this.$store.dispatch('getMaterialFromIndex', {
                        id: this.$root._route.params.id,
                        actual_id: id,
                        type: this.$root._route.params.type
                    }, {root: true});
                }

                this.refreshView++;
            },
            async handeGetMaterial(id, slug){
                this.titlePopupLearning = slug.replace('-', '_');

                await this.$store.dispatch('getMaterialBySlug', {
                    id: this.$root._route.params.id,
                    actual_id: id,
                    type: this.$root._route.params.type,
                    slug: slug
                }, {root: true});

                this.Global.openPopUp('popup_learning');
            },
            async handeSetSave(id){
                const formData = new FormData(document.getElementById('form_result_questionnaire'));

                await this.$store.dispatch('setQuestionnaireSave', {
                    id: this.$root._route.params.id,
                    actual_id: id,
                    type: this.$root._route.params.type,
                    formData: [...formData]
                }, {root: true});
            },
            async handeSetFinish(id){
                const formData = new FormData(document.getElementById('form_result_questionnaire'));

                await this.$store.dispatch('setQuestionnaireFinish', {
                    id: this.$root._route.params.id,
                    actual_id: id,
                    type: this.$root._route.params.type,
                    formData: [...formData]
                }, {root: true});
            },
            async getInformation(){
                await this.$store.dispatch('getQuestionnnaireInformation', {
                    id: this.$root._route.params.id,
                    actual_id: this.$root._route.params.id === this.getterQuestionnaireBodyContent.id ? null : this.getterQuestionnaireBodyContent.id,
                    type: this.$root._route.params.type
                }, {root: true});
            },
            async getLegend(){
                await this.$store.dispatch('getQuestionnnaireLegend', {
                    id: this.$root._route.params.id,
                    actual_id: this.$root._route.params.id === this.getterQuestionnaireBodyContent.id ? null : this.getterQuestionnaireBodyContent.id,
                    type: this.$root._route.params.type
                }, {root: true});
            },
            async getKnowMore(){
                this.titlePopupLearning = 'know_more';

                await this.$store.dispatch('getQuestionnnaireKnowMore', {
                    id: this.$root._route.params.id,
                    actual_id: this.$root._route.params.id === this.getterQuestionnaireBodyContent.id ? null : this.getterQuestionnaireBodyContent.id,
                    type: this.$root._route.params.type
                }, {root: true});

                this.Global.openPopUp('popup_learning');
            },
            async setFinishAllQuestionnaire(){
                await this.$store.dispatch('setFinishAllQuestionnaire', {
                    id: this.$root._route.params.id,
                    type: this.$root._route.params.type,
                    extra: this.$route.path.split('/')[this.$route.path.split('/').length - 1]
                }, {root: true});
            },
            async setEvaluate(){
                this.Global.windowOpen(this.Global.openSecondWindow('evaluation_questionnaire',{
                    id: this.$root._route.params.id,
                    actual_id: this.getterQuestionnaireBodyContent.id,
                    type: this.$root._route.params.type
                }),'evaluation_questionnaire_' + this.$root._route.params.id + '_' + this.getterQuestionnaireBodyContent.id);
            },
            async showAdvice(item){
                await this.$store.dispatch('getAdvice', {
                    id: this.$root._route.params.id,
                    actual_id: item.id,
                    type: this.$root._route.params.type
                }, {root: true});

                this.Global.openPopUp('popup_advice');
            },
            async showDetail(item){
                await this.$store.dispatch('getStartEvaluation', {
                    id: this.$root._route.params.id,
                    actual_id: item.id,
                    type: this.$root._route.params.type
                }, {root: true});

                this.Global.openPopUp('popup_evaluate');
            }
        }
    }
</script>

<style>
    [id^="see_advice_"] > img{
        margin-top: 1px !important;
    }
</style>