<template>
    <div class="card" @mouseover="hover = true" @mouseleave="hover = false">
        <div class="card-image">
            <div class="image-container">
                <img :src="data.src === undefined || data.src === null  || data.src === '' ? block.image : data.src" :class="{ zoom: hover }" alt="" />
            </div>
        </div>
        <div class="card-name" v-if="data.name !== undefined">
            <p class="ellipsis T-subtitle" :title="data.name">{{data.name}}</p>
        </div>
        <template v-if="block.progressBar">
            <div class="progress-bar-separator">
                <div class="full-bar-progress" :style="'background-color: ' + (hover ? block.colorsHover[0] : block.colorsHover[1])">
                    <div class="actual-bar-progress" :style="'background: linear-gradient(to right, ' + block.colors[0] + ', ' + block.colors[1] + '); background-size: 200% 200%; animation: gradient-animation 4s ease infinite; width: ' + data.progress + '%;'" style="width: 20%;"></div>
                </div>
            </div>
        </template>
        <div class="card-description" v-if="data.description !== undefined">
            <p :title="data.description" class="T-text">{{data.description }}</p>
        </div>
        <slot name="extraContent"/>
    </div>
</template>

<script>
    export default {
        name: 'gh_ce_card',
        props: ['data', 'block'],
        data() {
            return {
                hover: false
            }
        }
    }
</script>

<style>

    .card {
        display: grid;
        border-radius: 4px;
        flex-direction: column;
        align-items: flex-start;
        transition: box-shadow 0.3s;
        background-color: #FFFFFF;
        cursor: pointer;
        border: 1px solid #e0e0e0;
    }

    .card:hover {
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    }

    .card-name {
        display: inline-grid;
        color: #000;
        font-style: normal;
        line-height: 34px;
        padding-left: 10px;
        padding-right: 10px;
    }

    .card-image {
        position: relative;
        width: 100%;
        overflow: hidden;
        border-top-right-radius: 4px;
        border-top-left-radius: 4px;
    }

    .image-container {
        position: relative;
        width: 100%;
        padding-top: 56.25%;
    }

    .image-container img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: transform 0.5s ease;
    }

    .image-container img.zoom {
        transform: scale(1.2);
    }

    .progress-bar-separator{
        padding: 10px;
    }

    .card-description {
        padding: 10px;
        height: 75px;
        overflow: hidden;
        white-space: normal;
    }

    .card-description p {
        margin: 0;
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 4;
        max-height: 100%;
        color: black;
    }

    .full-bar-progress {
        width: 100%;
        height: 8px;
        border-radius: 10px;
    }

    .actual-bar-progress {
        height: 8px;
        border-radius: 10px;
    }
</style>