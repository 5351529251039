var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"section_line",staticStyle:{"margin-top":"10px"}},[_c('div',{staticClass:"T15_b",staticStyle:{"padding-left":"13px"}},[_vm._v(" "+_vm._s(_vm.$t('type') + ': ' + (_vm.$root._route.params.view ? Object.values(_vm.ConfigTypeExercise.data.type[0])[1] : _vm.$parent.$parent.$refs['RefTypeExercise'].datasaved.text))+" ")])]),_c('div',{staticClass:"gh-row-content",staticStyle:{"margin-top":"10px"}},[(!_vm.$root._route.params.view)?[_c('GhInputTextField',{ref:"RefPossibleAnswers",attrs:{"datalabel":{
                    text: _vm.$t('n_statements') + '*',
                    style: 'width: 150px; text-align: right;',
                    class: 'T15_b'
                },"datainput":{
                    required: true,
                    id: 'type_exercise_n_statements',
                    name: 'type_exercise[n_statements]',
                    style: 'width: 50px',
                    value: _vm.ConfigTypeExercise.data && _vm.ConfigTypeExercise.data.config_type !== undefined && _vm.ConfigTypeExercise.data.config_type.n_statements ? _vm.ConfigTypeExercise.data.config_type.n_statements : '',
                    type: 'int',
                    limitNumMin: 1
                }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('n_statements') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.ConfigTypeExercise.data && _vm.ConfigTypeExercise.data.config_type !== undefined && _vm.ConfigTypeExercise.data.config_type.n_statements ? _vm.ConfigTypeExercise.data.config_type.n_statements : ''))])]],2),_c('div',{staticClass:"gh-row-content",staticStyle:{"margin-left":"160px"}},[_c('div',{staticStyle:{"display":"flex","margin-left":"160px"}},[_c('GhChecks',{ref:"GhCheckConfigEvidence",attrs:{"datalabel":{text: _vm.$t('evidence'), style: 'width: 130px', id: 'label_type_exercise_evidence'},"datainput":{
                    required: false,
                    selected: _vm.ConfigTypeExercise.data && _vm.ConfigTypeExercise.data.config_type !== undefined && _vm.ConfigTypeExercise.data.config_type.evidence[0] === 1,
                    disabled: _vm.$root._route.params.view,
                    type: 'checkbox',
                    id: 'value_type_exercise_evidence',
                    value: 1,
                    name: 'type_exercise[evidence][]'
                }},on:{"click":function($event){return _vm.$parent.$parent.checkGroupChecks('GhCheckConfigEvidence', 'GhCheckConfigEvidenceRequired', false, true)}}}),_c('GhChecks',{ref:"GhCheckConfigEvidenceRequired",attrs:{"datalabel":{text: _vm.$t('mandatory'), style: 'width: 130px', id: 'label_type_exercise_required_evidence'},"datainput":{
                    required: false,
                    selected: _vm.ConfigTypeExercise.data && _vm.ConfigTypeExercise.data.config_type !== undefined && _vm.ConfigTypeExercise.data.config_type.evidence[1] === 2,
                    disabled: _vm.$root._route.params.view,
                    type: 'checkbox',
                    id: 'value_type_exercise_required_evidence',
                    value: 2,
                    name: 'type_exercise[evidence][]'
                }},on:{"click":function($event){return _vm.$parent.$parent.checkGroupChecks('GhCheckConfigEvidence', 'GhCheckConfigEvidenceRequired', true, true)}}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }